// import {gsap} from 'gsap';
// import DesignersMenuItem from './designersMenuItem';

import Scrollbar from 'smooth-scrollbar';

const isTouchDevice = 'ontouchstart' in window;

let _bounds, 
    infoWindow, 
    infowindowW, 
    infowindowH, 
    map, 
    mapContainer, 
    marker, 
    markerCluster,
    overlay,
    resultsEl,
    scrollbar,
    stores;

let positions = [];
let markers = [];
let StoresSearchPosition;

export default class StoresSearch {    
    constructor(options) {
        
        this.defaults = {
            autocomplete: 0,
            backgroundColor: '#fff',
            content: '',
            fitbounds: false,
            geolocationBtn: false,
            getClusterIcons: null,
            getMarkerIcon: null,
            getDistance: null,
            isDett: false,
            maxZoom: 18,
            params: null,
            otherdatas: null,
            searchBtn: null,
            searchCallback: null,
            url: '',
            zoom: 5,
            zoomControl: true,
            zoomControlPosition: null,
            zoomToMarker: false
        }
        
        this.Settings = {...this.defaults, ...options};
        
        // options:
        // loader = google loader
        // el = the stores search element (<#storesSearch>)    

        // this.loader = this.Settings.loader;
        this.el = this.Settings.el;
        
        // // map element
        this.mapEl = this.el.querySelector('#map');
        // // the stores elements
        this.stores = '.store';

        resultsEl = this.el.querySelector('.storesSearchResults .scroller');

        infoWindow = new google.maps.InfoWindow();

        StoresSearchPosition = {lat: params.latitude, lng: params.longitude}
        
        // // Init stores search
        this.Listeners();
        this.Init();
    }
    

    Listeners(){
        // Resize
        var doit;
        window.onresize = function(){
            clearTimeout(doit);
            doit = setTimeout(function(){
                google.maps.event.trigger(map, 'resize');
                map.setCenter(StoresSearchPosition);
                map.fitBounds(_bounds);
            }, 100);
        };

        // Autocomplete places
        if (this.el.querySelector('.storesSearchAutocomplete')) {
            // console.log('Autocomplete')
            let autocompleteInput = this.el.querySelector('.storesSearchAutocomplete');

            let autocompleteOptions = {
                fields: ["formatted_address", "geometry", "name"],
                strictBounds: false,
            };

            let autocomplete = new google.maps.places.Autocomplete(autocompleteInput, autocompleteOptions);

            autocomplete.addListener("place_changed", () => {
                let place = autocomplete.getPlace();
            
                if (!place.geometry || !place.geometry.location) {
                  // User entered the name of a Place that was not suggested and
                  // pressed the Enter key, or the Place Details request failed.
                  window.alert("No details available for input: '" + place.name + "'");
                  return;
                }
                
                // If the place has a geometry, then present it on a map.
                if (place.geometry.viewport) {
                    StoresSearchPosition = {
                        lat: place.geometry.location.lat,
                        lng: place.geometry.location.lng,
                    };

                    var lat = place.geometry.location.lat();
                    var lng = place.geometry.location.lng();

                    params.latitude = lat;
                    params.longitude = lng;

                    // console.log(StoresSearchPosition)

                    this.Search();
                }
            });
        }

        // geolocation
        if (navigator.geolocation) {
            // console.log('geolocation')
            navigator.geolocation.getCurrentPosition((position) => {
                StoresSearchPosition = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };

                params.latitude = position.coords.latitude;
                params.longitude = position.coords.longitude;
                // console.log(params.latitude)
                // console.log(params.longitude)

                this.Search();
            })
        }
    }


    Init() {
        // console.log('init')
        map = new this.Settings.Map(this.mapEl, {
            center: StoresSearchPosition,
            fullscreenControl: false,
            maxZoom: this.Settings.maxZoom,
            mapId: "thomMap",
            mapTypeControl: false,
            // panControl: true,
            // scrollwheel: false,
            streetViewControl: false,
            zoom: this.Settings.zoom,
            zoomControl: this.Settings.zoomControl,
            zoomControlOptions: {
                position: this.Settings.zoomControlPosition
            }
        });

        if(this.Settings.styled) {
            map.mapTypes.set("thomMap", new google.maps.StyledMapType(this.Settings.styled));
            map.setMapTypeId("thomMap");
        }
        
        // infowindow = new google.maps.InfoWindow();
        this.Search();
    }

    Search() {
        // console.log('Search')
        this.ClearMarker();

        this.LoaderShow();

        if(Scrollbar.has(resultsEl) === true){
            Scrollbar.destroy(resultsEl);
            scrollbar = null;            
        }

        // Look for radius
        if(this.el.querySelector('#mapRadius')){
            StoresSearchPosition.radius = this.el.querySelector('#mapRadius').val();

            params.radius = StoresSearchPosition.radius;
        }

        // Send params
        let data = typeof params !== 'undefined' && params != null ? Object.keys(params).map(function(k) { return encodeURIComponent(k) + '=' + encodeURIComponent(params[k])}).join('&') : {}

        var newSettingsUrl = this.Settings.url + '?' + (new URLSearchParams(data)).toString();
        

        // this.Settings.url += '?' + (new URLSearchParams(data)).toString();

        // console.log(newSettingsUrl)

        fetch(newSettingsUrl, {
            method: 'GET'
        }).then(
            response => {
                // The API call was successful!
                if (response.ok) {
                    return response.text();
                } else {
                    return Promise.reject(response.statusText);
                }
            }
        ).then(
            text => {
                // console.log(text)
                resultsEl.innerHTML = text;

                this.CreateStores();

                return true;
                // this.LoaderHide();
            }
        ).catch(function (err) {
            // There was an error
            console.warn('Something went wrong.', err);
        });
    }

    CreateStores() {
        
        scrollbar = Scrollbar.init(resultsEl, {
            alwaysShowTracks: true
        });

        stores = this.Settings.stores === null || this.Settings.stores === undefined ? this.el.querySelectorAll(this.stores) : this.el.querySelectorAll(this.Settings.stores);

        var count = 1;
        
        if(stores.length > 0) {

            [...stores].forEach(store => {
                // console.log(count)

                let position = {
                    lat: store.dataset.lat,
                    lng: store.dataset.lng,
                };

                if(store.dataset.type){
                    position.type = store.dataset.type;
                }

                positions.push(position);

                let latLng = new this.Settings.LatLng(store.dataset.lat, store.dataset.lng);

                let marker = new google.maps.marker.AdvancedMarkerElement({
                    map,
                    position: latLng,
                    content: this.InfoWindowMarker(store),
                    collisionBehavior: google.maps.CollisionBehavior.REQUIRED
                });

                markers.push(marker);
                _bounds.extend(marker.position);


                if(isTouchDevice) {
                    // marker listener
                    marker.addListener("click", () => {
                        this.HighlightMarker(marker, store, markers, stores, _bounds, position);
                        map.setCenter(latLng);
                    });

                    // agency listener
                    // store.addEventListener("click", () => {
                    //     this.HighlightMarker(marker, store, markers, stores, _bounds, position);
                    //     map.setCenter(latLng);
                    // });

                } else {

                    marker.addListener("click", () => {
                        this.HighlightMarker(marker, store, markers, stores, _bounds, position);
                        map.setCenter(latLng);

                        scrollbar.scrollIntoView(store, {
                            onlyScrollIfNeeded: true,
                        });
                    });

                    store.addEventListener("click", () => {
                        this.HighlightMarker(marker, store, markers, stores, _bounds, position);
                        map.setCenter(latLng);
                    });


                    marker.content.querySelector('.icon').onmouseover = (event) => {
                        // marker.content.querySelector('.icon').classList.add('bounce');
                        store.classList.add('selected');
                    }
                    marker.content.querySelector('.icon').onmouseout = (event) => {
                        // marker.content.querySelector('.icon').classList.remove('bounce');
                        store.classList.remove('selected');
                    }

                    store.onmouseover = (event) => {
                        marker.content.querySelector('.icon').classList.add('bounce');
                    };

                    store.onmouseout = (event) => {
                        marker.content.querySelector('.icon').classList.remove('bounce');
                    };
                }

                
                if(count === stores.length) {
                    // console.log(count)
                    this.LoaderHide();
                    setTimeout(function(){
                        map.fitBounds(_bounds);
                    }, 500)
                }

                count++;

            });
            
        }
    }

    InfoWindowMarker(item) {
        let content = document.createElement("div");
        var icon = this.IconMarker(item.dataset.type);
        var txt = item.querySelector('.infowindow').innerHTML;
    
        content.classList.add("storeInfoWindow");
        content.innerHTML = `
            <div class="icon">
                <img src="${icon.url}" width="${icon.width}" height="${icon.height}">
            </div>
            <div class="iw-wrapper">
              <div class="iw-close"><span></span></div>
              <div class="iw-content">
                ${txt}
              </div>
            </div>
            `;
        return content;
    }

    HighlightMarker(markerView, store, markers, stores, _bounds, position) {
        let markerClose = (markerView) => {
            // map.setCenter(position);
            scrollbar.scrollTo(0, 0, 300);

            setTimeout(function(){
                markerView.zIndex = null;
                markerView.content.classList.remove("active");
                store.classList.remove("active");
                map.fitBounds(_bounds);
            }, 100)
        }
        
        if (markerView.content.classList.contains("active")) {
            markerClose(markerView);
        } else {
            for(let i = 0; i < markers.length; i++){
                markers[i].content.classList.remove("active");
                stores[i].classList.remove("active");
            }
    
            var markerBounds = new google.maps.LatLngBounds();
    
            // Create bounds from marker
            markerBounds.extend(markerView.position);
    
            // Don't zoom in too far on only one marker
            if (markerBounds.getNorthEast().equals(markerBounds.getSouthWest())) {
                var extendPoint1 = new this.Settings.LatLng(
                    markerBounds.getNorthEast().lat() + 0.01,
                    markerBounds.getNorthEast().lng() + 0.01
                )
                var extendPoint2 = new this.Settings.LatLng(
                    markerBounds.getNorthEast().lat() - 0.01,
                    markerBounds.getNorthEast().lng() - 0.01
                )
                markerBounds.extend(extendPoint1)
                markerBounds.extend(extendPoint2)
            }
            
            markerView.content.classList.add("active");
            markerView.zIndex = 1000;
            store.classList.add("active");
    
            if (document.querySelector('.sedi--map-results')) {
                let content = store.querySelector(".txt");
                content.style.maxHeight = "none";
            }
    
            map.fitBounds(markerBounds);
    
            markerView.content.querySelector('.iw-close').addEventListener('click', function(){           
                markerClose(markerView);
            })
        }
    }

    ClearMarker() {
        for (let i = 0; i < markers.length; i++) {
            markers[i].setMap(null);
        }
        markers = [];

        _bounds = new google.maps.LatLngBounds();
    }

    IconMarker(type) {
        var category = "default";
      
        if (type != undefined) {
          category = type;
        }
      
        var markerIcon = {
          default: {
            url: "/img/marker.svg",
            height: 24,
            width: 24,
          },
          main: {
            url: "/img/marker-main.png",
            height: 78,
            width: 78,
          },
        };
      
        return markerIcon[category];
    }

    LoaderShow() {
        this.loader = document.createElement('div');
        this.loader.className = 'loader';
        
        this.spinner = document.createElement('span');
        // this.spinner.className = 'spinner';
        this.spinner.className = 'brand';
        this.spinner.innerHTML = document.querySelector('.navbar--brand').innerHTML;

        this.loader.appendChild(this.spinner);
        this.el.appendChild(this.loader);

        document.querySelector('.loader').classList.add('show');
    }

    LoaderHide() {
        document.querySelector('.loader').classList.remove('show');
        setTimeout(() => {
            document.querySelector('.loader').remove()
        }, "500");
    }
    
}