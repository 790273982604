// THOM Dropdown
// import mCustomScrollbar from "malihu-custom-scrollbar-plugin";
// import mousewheel from "jquery-mousewheel";

const thomDropdown = (container) => {
	/* MOBILE Action */
	var nav = function (elem) {
		elem.querySelector('.label').onclick = (e) => {
			e.preventDefault();
			container.querySelectorAll('.thom-dropdown').forEach((dropdown) => {
				if(dropdown != elem){
					dropdown.classList.remove('is-open')
				}
			});
			
			elem.classList.contains('is-open') ? elem.classList.remove('is-open') : elem.classList.add('is-open');

			scrollbar(elem);
			// return false;
		}

		document.addEventListener("click", (e) => {
			// Check if the filter list parent element exist
			const isClosest = e.target.closest('.thom-dropdown');
			// If `isClosest` equals falsy & popup has the class `show`
			// then hide the popup
			if (!isClosest) {
				container.querySelectorAll('.thom-dropdown').forEach((dropdown) => {
					if(dropdown.classList.contains('is-open')){
						dropdown.classList.remove('is-open')
					}
				});
			}
		});
	}

	var scrollbar = function (elem) {
		var elems = elem.querySelectorAll('.options--item');
		var elems_n = elems.length;
		var elems_H = 0;
		var elems_max = elem.dataset.max;

		if (elems_n > elems_max) {
			if (elems.length > elems_max) {
				for(var i = 0; i < elems_n; i++){
					elems_H += elems[i].offsetHeight;
					
					if (i == elems_max - 1) {
						elem.querySelector('.scroller').style.height = elems_H + 'px';
					}
				}
			}
		}
	}

	let dropdown = container.getElementsByClassName('thom-dropdown');
	for( var i = 0; i <= dropdown.length; i++) {
		var _ = dropdown[i];
		if (_) {
			nav(_);
		}
	}
}
export {thomDropdown};
