
import barba from '@barba/core';
import { navbar } from "../modules/navbar/navbar";
import MultiMenu from 'multi-menu' // when you use webpack/rollup
import 'multi-menu/css/multi-menu.css' // when you use webpack/rollup
import utils from 'fizzy-ui-utils';
import LazyLoad from "vanilla-lazyload";
import { Loader } from "@googlemaps/js-api-loader";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import {thomDropdown} from "../modules/dropdown/dropdown";
import Scrollbar from 'smooth-scrollbar';
import A11yDialog from 'a11y-dialog';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import lightGallery from 'lightgallery';
import lgMediumZoom from 'lightgallery/plugins/mediumZoom';
// import lgVideo from 'lightgallery/plugins/video';
import Pristine from 'pristinejs';
import {preloader} from './components/preloader';
import DesignersMenu from './components/designersMenu';
import StoresSearch from './components/storesSearch';

// import Swiper JS
import Swiper from 'swiper';
import { EffectFade, Autoplay } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/swiper.css';
import 'swiper/modules/effect-fade.css';
import 'swiper/modules/effect-fade-element.css';

import { getDomIndex, isTouchDevice, mapStyle } from './components/utils';
import { formReset } from './components/form';

const imagesLoaded = require('imagesloaded');

// fade in and slide up content 
// on Intersection Observer with ScrollTrigger
const animFadeIn = (animFadeInEl) => {
    animFadeInEl.forEach((el, i) => {
        // Set up your animation
        let animFadeInAnim = gsap.to(el, {duration: 1, autoAlpha: 1, y: 0, paused: true});
        // Use callbacks to control the state of the animation
        ScrollTrigger.create({
          trigger: el,
          start: "top 90%",
          end: "bottom 90%",
          once: true,
          // markers: true,
          onEnter: self => {
            // If it's scrolled past, set the state
            // If it's scrolled to, play it
            self.progress === 1 ? animFadeInAnim.progress(1) : animFadeInAnim.play()
          }
        });
    });
}

// fade in
const fadeIn = (el, smooth = true, displayStyle = 'block') => {
    el.style.opacity = 0;
    el.style.display = displayStyle;
    if (smooth) {
        let opacity = 0;
        let request;

        let animation = () => {
            el.style.opacity = opacity += 0.04;
            if (opacity >= 1) {
                opacity = 1;
                cancelAnimationFrame(request);
            }
        };

        let rAf = () => {
            request = requestAnimationFrame(rAf);
            animation();
        };
        rAf();

    } else {
        el.style.opacity = 1;
        el.style.display = displayStyle;
    }
};
// fade out
const fadeOut = (el, smooth = true, displayStyle = 'none') => {
    if (smooth) {
        let opacity = el.style.opacity;
        let request;

        let animation = () => {
            el.style.opacity = opacity -= 0.04;
            if (opacity <= 0) {
                opacity = 0;
                el.style.display = displayStyle;
                cancelAnimationFrame(request);
            }
        };

        let rAf = () => {
                request = requestAnimationFrame(rAf);
                animation();
        };
        rAf();

    } else {
        el.style.opacity = 0;
        el.style.display = displayStyle;
    }
};

let animateProgressBarIsPaused = false;
let animateProgressBarInterval = 0;
function animateProgressBar(progressBar, duration, slider) {
    let progress = 0;
    let increment = 1 / duration * 100; // Adjust the increment value for desired smoothness
  
    animateProgressBarInterval = setInterval(() => {
        if(!animateProgressBarIsPaused) {
            progress += increment;
            progressBar.style.width = `${progress}%`;
        }
  
      if (progress >= 100) {
        clearInterval(animateProgressBarInterval);
        homeCarouselChangeSlide(slider)
      }
    }, 10); // Adjust the interval duration for desired speed
}

const homeCarouselChangeSlide = (slider) => {
    if(slider.activeIndex + 1 === slider.slides.length){
        slider.slideTo(0);
    } else {
        slider.slideNext();
    }
}

// Home Carousel
const homeCarouselInit = (target) => {
    let homeCarousel = target.querySelector('.home-carousel');
    let homeCarouselEl = homeCarousel.querySelector('.carousel-wrap');
    let delay = homeCarousel.getAttribute('data-autoplay-delay') != null ? Number(homeCarousel.getAttribute('data-autoplay-delay')) : 3000;

    let cellsButtonGroup = homeCarouselEl.querySelector('.carousel-nav--cells');
    let arrows = homeCarouselEl.querySelectorAll('.arrow');
    let homeCarouselTitles = homeCarousel.querySelector('.carousel-title');

    const homeSwiper = new Swiper(homeCarouselEl, {
        modules: [ EffectFade, Autoplay ],
        lazy: true,
        init: false,
        effect: "fade",
        fadeEffect: {
            crossFade: true
        },
        slideActiveClass: 'is-selected',
        // watchSlidesProgress: true,
    });
    
    let updateSlider = () => {
        let previousSelectedButton = cellsButtonGroup.querySelector('.is-selected');
        let selectedButton = cellsButtonGroup.children[homeSwiper.activeIndex];
        let selectedText = homeCarouselTitles.children[homeSwiper.activeIndex];
        let progressBar = selectedButton.querySelector('.progress');
        
        if(previousSelectedButton){
            previousSelectedButton.classList.remove('is-selected');
        }

        if(selectedButton){
            selectedButton.classList.add('is-selected');
            selectedButton.querySelector('.progress').style.width = 0 + '%';
        }

        homeCarouselTitles.querySelectorAll('.text').forEach((text) => {
            text.classList.remove('selected');
        });

        if(selectedText){
            selectedText.classList.add('selected');
        }

        clearInterval(animateProgressBarInterval);

        for (let child of cellsButtonGroup.children) {            
            if(getDomIndex(child) > homeSwiper.activeIndex){
                child.querySelector('.progress').style.width = 0 + '%';
            } else if(getDomIndex(child) < homeSwiper.activeIndex) {
                child.querySelector('.progress').style.width = 100 + '%';
            }
        }

        animateProgressBar(progressBar, delay/10, homeSwiper);
    }

    let createCursorFollower = () => {
        let $el = document.querySelector('.cursor-follower');
        homeCarousel.addEventListener('mousemove', (e) => {
            let bounds = homeCarousel.getBoundingClientRect();
            let x = e.clientX - bounds.left;
            let y = e.clientY - bounds.top;
          
            gsap.to($el, {
                x: x - $el.clientWidth / 2,
                y: y - $el.clientHeight / 2,
                duration: 0.7,
                ease: 'power4', // More easing options here: https://gsap.com/docs/v3/Eases/
                opacity: 1
            });
        });
        
        // is moved out of the div
        homeCarousel.addEventListener('mouseleave', (e) => {
          gsap.to($el, {
            duration: 0.7,
            opacity: 0,
          });
        });
    };

    arrows.forEach((arrow) => {
        arrow.onmouseover = function(event) {
            homeCarousel.querySelector('.cursor-follower').textContent = arrow.dataset.cursor;
        };

        if(arrow.classList.contains('arrow--prev')){
            arrow.addEventListener( 'click', function() {
                if(homeSwiper.activeIndex === 0){
                    homeSwiper.slideTo(homeSwiper.slides.length - 1);
                } else {
                    homeSwiper.slidePrev();
                }
                // homeSwiper.autoplay.pause();
            });
        } else if (arrow.classList.contains('arrow--next')) {
            arrow.addEventListener( 'click', function() {
                // animateProgressBarIsPaused = false;
                if(homeSwiper.activeIndex + 1 === homeSwiper.slides.length){
                    homeSwiper.slideTo(0);
                } else {
                    homeSwiper.slideNext();
                }
                // homeSwiper.autoplay.pause();
            });
        }
    });

    homeSwiper.on('slideChangeTransitionEnd', function () {
        updateSlider();
    });

    homeSwiper.on('autoplayPause', function () {
        // console.log('autoplay Pause')
        animateProgressBarIsPaused = true;
    });
    homeSwiper.on('autoplayResume', function () {
        // console.log('autoplay Resume')
        animateProgressBarIsPaused = false;
    });

    homeSwiper.on('init', function() {
        // console.log('init');
        for(var i = 0; i < homeSwiper.slides.length; i++){
            var btn = document.createElement("button");
            btn.classList.add('button');
            var progress = document.createElement("div");
            progress.classList.add('progress');
            btn.appendChild(progress);
            if(homeSwiper.slides[i].querySelector('.text')){
                var cta = homeSwiper.slides[i].querySelector('.text').cloneNode(true);
                homeCarouselTitles.appendChild(cta);
            }
            
            if(i === 0){
                btn.classList.add('is-selected');
            }
    
            cellsButtonGroup.append(btn);
        }

        let cellsButtons = utils.makeArray(cellsButtonGroup.children);

        // Buttons click
        cellsButtonGroup.addEventListener( 'click', function(event) {
            if (!event.target.matches('.button')) {
                return;
            }
            let index = cellsButtons.indexOf(event.target);
            homeSwiper.slideTo(index);
        });

        cellsButtonGroup.addEventListener("mouseenter", () => {            
            // homeSwiper.autoplay.pause();
            animateProgressBarIsPaused = true;

            gsap.to(homeCarousel.querySelector('.cursor-follower'),{
                autoAlpha: 0,
                duration: 0.25,
            })
        });
        cellsButtonGroup.addEventListener("mouseleave", () => {
            // homeSwiper.autoplay.resume();
            animateProgressBarIsPaused = false;
            gsap.to(homeCarousel.querySelector('.cursor-follower'), {
                autoAlpha: 1,
                duration: 0.25,
            })
        });

        homeCarouselTitles.addEventListener("mouseenter", () => {
            // homeSwiper.autoplay.pause();
            animateProgressBarIsPaused = true;
        });
        homeCarouselTitles.addEventListener("mouseleave", () => {
            // homeSwiper.autoplay.resume();
            animateProgressBarIsPaused = false;
        });

        if (!isTouchDevice()) {
            createCursorFollower();
        }

        // Mouse enter/leave to play/pause autoplay
        // homeCarousel.addEventListener("mouseenter", () => {
        //     homeSwiper.autoplay.pause();
        //     animateProgressBarIsPaused = true;
        // });
        // homeCarousel.addEventListener("mouseleave", () => {
        //     homeSwiper.autoplay.resume();
        //     animateProgressBarIsPaused = false;
        // });

        updateSlider();
    });

    homeSwiper.on('afterInit', function() {
        homeCarousel.querySelector('.loader').classList.remove('show');
        setTimeout(() => {
            homeCarousel.querySelector('.loader').remove()
        }, "500");
    });

    var nodes = homeCarousel.querySelectorAll('.carousel-cell');
    var first = nodes[0];

    imagesLoaded(first, () => {
        // console.log('imagesLoaded')
        homeSwiper.init();
    });
}

// Sections Carousel
const carouselInit = (carousel) => {
    let carouselEl = carousel.querySelector('.carousel-wrap');
    let arrows =  carousel.querySelectorAll('.arrow');

    let carouselSwiper = new Swiper(carouselEl, {
        loop: true,
        init: false,
        slideActiveClass: 'is-selected',
        speed: 450
    });

    let updateSwiperN = () => {
        var number = carouselEl.querySelector('.carousel-number');
        number.innerHTML = (carouselSwiper.realIndex +  1) + '/' + carouselSwiper.slides.length;
    }

    carouselSwiper.on('slideChangeTransitionEnd', function(){
        updateSwiperN();
    })

    let createCursorFollower = () => {
        let $el = carousel.querySelector('.cursor-follower');
        carousel.addEventListener('mousemove', (e) => {
          let bounds = carousel.getBoundingClientRect();
            let x = e.clientX - bounds.left;
            let y = e.clientY - bounds.top;
          
          gsap.to($el, {
            x: x - $el.clientWidth / 2,
            y: y - $el.clientHeight / 2,
            duration: 0.7,
            ease: 'power4', // More easing options here: https://gsap.com/docs/v3/Eases/
            opacity: 1
          });
        });
        
        // is moved out of the div
        carousel.addEventListener('mouseleave', (e) => {
          gsap.to($el, {
            duration: 0.7,
            opacity: 0,
          });
        });
    };

    arrows.forEach((arrow) => {
        arrow.onmouseover = function(event) {
            carousel.querySelector('.cursor-follower').textContent = arrow.dataset.cursor;
        };

        if(arrow.classList.contains('arrow--prev')){
            arrow.addEventListener( 'click', function() {                
                // if(carouselSwiper.activeIndex === 0){
                //     carouselSwiper.slideTo(carouselSwiper.slides.length - 1);
                // } else {
                //     carouselSwiper.slidePrev();
                // }
                carouselSwiper.slidePrev();
            });
        } else if (arrow.classList.contains('arrow--next')) {
            arrow.addEventListener( 'click', function() {                
                // if(carouselSwiper.activeIndex + 1 === carouselSwiper.slides.length){
                //     carouselSwiper.slideTo(0);
                // } else {
                //     carouselSwiper.slideNext();
                // }
                carouselSwiper.slideNext();
            });
        }
    });

    carouselSwiper.on('init', function() {
        updateSwiperN();

        if (!isTouchDevice()) {
            createCursorFollower();
        }
    });

    carouselSwiper.init();
}

// Google map loader
const loader = new Loader({
    apiKey: _gmapkey,
    version: "weekly",
    libraries: ["places"]
});

// Form Response Action
function MyThomFormResponseAction(title, text) {
    var mytitle = '';

    if (title !== undefined && title !== null && title !== "") {
      mytitle = "<div class='title md'><strong>" + title + "</strong></div>";
    } else {
      mytitle = "";
    }
  
    var myHtml =
        mytitle +
        "<p class='md'>" + text + "</p>";
      
    document.querySelector("#alert-dialog .dialog-text").innerHTML = myHtml;

    var dialogAlert = new A11yDialog(document.getElementById('alert-dialog'));

    dialogAlert
        .on('show', () => disableBodyScroll(dialogAlert))
        .on('hide', () => enableBodyScroll(dialogAlert))

    dialogAlert.show()
}

let grecaptchaBadgeShow = () => {
    document.querySelector(".grecaptcha-badge").classList.remove('hide');
}
let grecaptchaBadgeHide = () => {
    document.querySelector(".grecaptcha-badge").classList.add('hide');
}

// Pristine validation configuration
const defaultPristineConfig = {
    // class of the parent element where the error/success class is added
    classTo: 'form-group',
    errorClass: 'has-danger',
    successClass: 'has-success',
    // class of the parent element where error text element is appended
    errorTextParent: 'form-group',
    // type of element to create for the error text
    errorTextTag: 'div',
    // class of the error text element
    errorTextClass: 'text-help' 
};

const slideDown = element => element.style.height = 'auto';
const slideUp = element => element.style.height = 0;

const App = (container, data) => {
    // console.log('function APP')

    navbar(container);
    // Mobile menu
    new MultiMenu(container.querySelector('.js-multi-menu'), {
        prependHTML: '',
    });

    let myLazyLoad = new LazyLoad({
        container: container,
        unobserve_entered: true,
        elements_selector: ".lazy",
        callback_loaded: function(el){
            // locoScroll.update();
        }
    });

    // Fade in animation
    if(container.querySelector('.fadeIn')){
        const animFadeInEl = gsap.utils.toArray('.fadeIn');
        // console.log(animFadeInEl)
        // Set things up
        gsap.set(animFadeInEl, {autoAlpha: 0, y: 20});
        animFadeIn(animFadeInEl);
    }

    // Home Carousel
    if(container.querySelector('.home-carousel')){
        homeCarouselInit(container);
    }

    // if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
    //     let flipLinks = container.querySelectorAll('.flip');
    //     if(flipLinks.length > 0){
    //         flipLinks.forEach((flip) => {
    //             flip.classList.add('notHover');
    //         })
    //     }
    // }

    if(container.querySelector('.carousel')){
        var carousels = container.querySelectorAll('.carousel');
        [...carousels].forEach(el => {
            let items = el.querySelectorAll('.carousel-cell');
            if(items.length > 1){
                carouselInit(el);
            }
        })
    }

    if(container.querySelector('#productConfigurator')){
        let productConfiguratorElement = container.querySelector('#productConfigurator');
        let scrollingPropagation = productConfiguratorElement.querySelector('.scrolling-propagation');
        let iframeActive = 0;
        let cta = scrollingPropagation.querySelector('.cta');
        let productConfiguratorIframe = productConfiguratorElement.querySelector('#iframeConfigurator');
        
        
        cta.addEventListener('click', function(){
            document.querySelector('html').style.overflowY = 'unset';
            productConfiguratorElement.classList.add('active');
            disableBodyScroll(productConfiguratorElement);
            if(iframeActive == 0) {
                productConfiguratorElement.classList.add('loaded');
                productConfiguratorIframe.setAttribute("src", productConfiguratorIframe.getAttribute('data-src'));
                iframeActive = 1;
            }
        });

        productConfiguratorElement.addEventListener('mouseleave', function(){
            document.querySelector('html').style.overflowY = 'scroll';
            productConfiguratorElement.classList.remove('active');
            enableBodyScroll(productConfiguratorElement);
        });
    }

    // Accordion
    if(container.querySelector(".accordion--list")){
        let accordionList = container.querySelectorAll(".accordion--list");

        accordionList.forEach((list) => {
            let accordions = list.querySelectorAll(".accordion--item");
            
            accordions.forEach((accordion) => {
                let intro = accordion.querySelector(".accordion--title");
    
                let openAccordion = (accordion) => {
                    let content = accordion.querySelector(".accordion--content");
                    accordion.classList.add("active");
                    slideDown(content);
                };
        
                let closeAccordion = (accordion) => {
                    let content = accordion.querySelector(".accordion--content");
                    accordion.classList.remove("active");
                    slideUp(content);
                };
    
                intro.onclick = () => {
                    if (accordion.classList.contains('active')) {
                        closeAccordion(accordion);
                    } else {
                        if(!list.classList.contains('multy')){
                            accordions.forEach((accordion) => closeAccordion(accordion));
                        }
                        openAccordion(accordion);
                    }
                };

                if(accordion.classList.contains('active')){
                    openAccordion(accordion);
                }
            });  
        });        
    }

    // Scroller on Buttons
    if(container.querySelector(".buttons--wrap")){
        let buttonsWraps = container.querySelectorAll(".buttons--wrap");
        [...buttonsWraps].forEach((wrap) => {
            let buttonsScroller = wrap.querySelector(".scroller");
            if(buttonsScroller){
                Scrollbar.init(buttonsScroller, {
                    alwaysShowTracks: false
                });
            }
        })
    }

    // Filters
    if(container.querySelector(".filterable")){
        let filterable = container.querySelectorAll(".filterable");

        [...filterable].forEach((element) => {

            let filterableButtons = element.querySelectorAll(".filterable--buttons *[data-filter]");
            let filterableElements = element.querySelectorAll(".filterable--elements *[data-type]");

            let isExpandable = element.classList.contains('expandable') ? true : false;
            let minItems = element.getAttribute("data-max-quantity");
            let buttonShow = element.querySelector(".expandable--show");
            let buttonHide = element.querySelector(".expandable--hide");
            let activeElements = [];
            let expandableElements = [];

            let defaultDisplay = window.getComputedStyle(filterableElements[0], null).display;
            
            let showHideElements = (el, filter) => {
                let type = el.getAttribute('data-type');

                if (type != null && type.includes(filter)) {
                    fadeIn(el, false, defaultDisplay);
                    activeElements.push(el);
                } else {
                    fadeOut(el, false);
                }
            }

            let expandableInit = () => {
                if(activeElements.length > minItems){
                    element.querySelector('.expandable--buttons').classList.remove('is-hidden');
                    buttonHide.classList.add('is-hidden');
                    buttonShow.classList.remove('is-hidden');
                } else {
                    element.querySelector('.expandable--buttons').classList.add('is-hidden');
                }

                for(var i = 0; i < activeElements.length; i++){
                    var el = activeElements[i];
                    if(i >= minItems){
                        el.classList.add('is-hidden');
                        expandableElements.push(el);
                    }
                }
            }
            
            filterableButtons.forEach((button) => {
    
                let filter = button.getAttribute('data-filter');

                if(button.classList.contains('selected')){
                    filterableElements.forEach((el) => {
                        showHideElements(el, filter);

                        if(isExpandable && getDomIndex(el) >= filterableElements.length - 1){
                            expandableInit();
                        }
                    });
                }
                
                button.onclick = () => {    
                    filterableButtons.forEach((button) => button.classList.remove("selected"));

                    if(isExpandable){
                        activeElements = [];
                        expandableElements = [];
                    }

                    filterableElements.forEach((el) => {
                        if(isExpandable){
                            el.classList.remove('is-hidden');
                        }
                        showHideElements(el, filter);
                        
                        if(isExpandable && getDomIndex(el) >= filterableElements.length - 1){
                            expandableInit();
                        }
                    });

                    button.classList.add('selected');
                };
            });

            if(isExpandable){                
                
                buttonShow.onclick = () => {
                    expandableElements.forEach((el) => {
                        el.classList.remove('is-hidden');
                    })
                    buttonShow.classList.add('is-hidden');
                    buttonHide.classList.remove('is-hidden');
                }

                buttonHide.onclick = () => {                    
                    expandableElements.forEach((el) => {
                        el.classList.add('is-hidden');
                    })
                    buttonHide.classList.add('is-hidden');
                    buttonShow.classList.remove('is-hidden');
                }
            }

        })      
    }

    // Designers
    if(container.querySelector(".designers--list")){
        const designersListEl = container.querySelector('.designers--list');
        preloader('.designers--item').then(() => {
            
            let letter = null;

            if(window.matchMedia("(min-width: 920px)").matches){
                new DesignersMenu(designersListEl);

                container.querySelectorAll('.designers--item').forEach((item) => {
                    let name = item.querySelector('.inner').textContent;
                    let firstLetter = name.charAt(0);

                    if(firstLetter != letter){
                        letter = firstLetter;
                        
                        let alphabetLetter = document.createElement('div');
                        alphabetLetter.className = 'letter';
                        alphabetLetter.innerHTML = letter;

                        item.appendChild(alphabetLetter)
                    }

                })
            }
        })
    }

    // Stores Search
    if(container.querySelector("#storesSearch")){
        let storesSearchEl = container.querySelector("#storesSearch");
        let storesElements = ".dealer";

        loader.load().then(async (google) => {
            let { Map, InfoWindow } = await google.maps.importLibrary("maps");
            let { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
            let { LatLng } = await google.maps.importLibrary("core");
            let collisionBehavior = google.maps.CollisionBehavior.REQUIRED;

            let StoreSearchOptions = {
                Map: Map,
                AdvancedMarkerElement: AdvancedMarkerElement,
                LatLng: LatLng,
                collisionBehavior: collisionBehavior,
                el: storesSearchEl,
                // stores: storesElements,
                styled: mapStyle,
                searchCallback: function(){
                    Scrollbar.init(document.querySelector('.storesSearchResults .scroller'), {
                        alwaysShowTracks: true
                    });    
                },
                zoomControlPosition: google.maps.ControlPosition.RIGHT_TOP,
                zoom: 8,
                maxZoom: 18,
                url: SearchStoresUrl
            }
            
            new StoresSearch(StoreSearchOptions);
        })
    }

    thomDropdown(container);

    // Privacy Dialog
    var dialogPrivacyEl = document.getElementById('privacy-dialog');
    var dialogPrivacy = new A11yDialog(dialogPrivacyEl);

    dialogPrivacy
        .on('show', () => disableBodyScroll(dialogPrivacyEl))
        .on('hide', () => enableBodyScroll(dialogPrivacyEl))
    
    var ajaxModalButtons = document.querySelectorAll("[rel='ajax:modal']");
    ajaxModalButtons.forEach((btn) => {
        btn.onclick = (event) => {
            event.preventDefault();
            var page = btn.getAttribute('href');

            let xhttp = new XMLHttpRequest();
            
            xhttp.onload = function() {
                var resp = this.responseText;  
                var parser = new DOMParser();
                var xmlDoc = parser.parseFromString(resp, "text/html");
                var tds = xmlDoc.querySelector(".privacy--text");

                dialogPrivacyEl.querySelector('.dialog-text').innerHTML = tds.innerHTML;

                Scrollbar.init(document.querySelector('.dialog-wrap'), {
                    alwaysShowTracks: false
                });

                dialogPrivacy.show()
            }

            xhttp.open("GET", page);
            xhttp.send();
        }
    })

    // if(container.querySelector('.open-gallery')){
    //     let galleryButtons = container.querySelectorAll('.open-gallery');

    //     galleryButtons.forEach((btn)  => {
    //         let gallery = document.getElementById(btn.getAttribute('data-gallery'));

    //         lightGallery(gallery, {
    //             mode: 'lg-fade',
    //             plugins: [lgVideo],
    //             licenseKey: 'EB342F23-EEA8-48C2-B726-0CFCE5A4AECF',      
    //             selector: "img",
    //             counter: false,
    //             download: false,
    //             getCaptionFromTitleOrAlt: false,
    //             mobileSettings: {
    //                 showCloseIcon: true
    //             },
    //             vimeoPlayerParams: {
    //               byline : 0,
    //               portrait : 0,
    //               dnt: true
    //             },
    //             youTubePlayerParams: {
    //               modestbranding : 1,
    //               showinfo : 0,
    //               controls : 0,
    //               rel: 0
    //             }
    //         });

    //         btn.addEventListener('click', function() {                
    //             gallery.querySelector("img").click();
    //         });
    //     });

        

    // }

    document.querySelectorAll('.lg-btn').forEach((btn)  => {
        lightGallery(btn, {
            licenseKey: 'EB342F23-EEA8-48C2-B726-0CFCE5A4AECF',
            // plugins: [lgMediumZoom],
            // startClass: "lg-start-zoom",
            zoomFromOrigin: false,
            zoom: false,
            selector: "this",
            counter: false,
            download: false,
            subHtmlSelectorRelative: true,
            getCaptionFromTitleOrAlt: false
        })
    })
    
    let forms = container.querySelectorAll('.thom-form');
    if(document.querySelector(".grecaptcha-badge")){
        document.querySelector('.thom-form:not(#newsletterForm)') ? document.querySelector(".grecaptcha-badge").classList.remove('hide') : document.querySelector(".grecaptcha-badge").classList.add('hide');
    }
    if(forms){

        forms.forEach((form) => {
            let validateForm = new Pristine(form, defaultPristineConfig);

            var spinner = document.createElement('span');
            spinner.className = 'brand';
            spinner.innerHTML = document.querySelector('.navbar--brand').innerHTML;

            form.querySelector('.thom-form--loader').appendChild(spinner);

            form.addEventListener('submit', function (e) {
                e.preventDefault();
                form.classList.add('loading');

                let valid = validateForm.validate(); // returns true or false

                if(form.querySelector(".GoogleCaptchaToken")){
                    var rSiteKey = form.querySelector('button[type=submit]').dataset.sitekey;
                    var rAction = form.querySelector('button[type=submit]').dataset.action;
                    // console.log(rSiteKey)
                    grecaptcha.ready(function() {
                        // console.log('grecaptcha.ready')
                        grecaptcha.execute(rSiteKey, {action: rAction}).then(function(token) {
                            // Add your logic to submit to your backend server here.
                            // Response Form
                            // console.log(token)
                            form.querySelector(".GoogleCaptchaToken").value = token;
                            FormSubmit(valid);
                        });
                    });
                } else {
                    FormSubmit(valid);
                }
            })
            
            let FormSubmit = (valid) => {
                if(valid){
                    let formData = new FormData(form);
                    // console.log(Array.from(formData));
        
                    fetch(form.getAttribute('action'), {
                        method: form.getAttribute('method'),
                        body: formData
                    }).then(response => {
                        // The API call was successful!
                        if (response.ok) {
                            return response.json();
                        } else {
                            return Promise.reject(response);
                        }
                    }).then(function (data) {
                        // console.log(data)
                        if(data.Status){
                            if(data.Url !== undefined && data.Url !== null && data.Url !== ""){
                                window.location.href = data.Url
                            } else {
                                if(data.Message){
                                    MyThomFormResponseAction(data.Title, data.Message);
                                }
                            }
                            
                            formReset(form);
                        } else {
                            if(data.Message){
                                MyThomFormResponseAction(data.Title, data.Message);
                            }
                            form.classList.remove('loading');
                        }
        
                        return true;
                    }).catch(function (err) {
                        // There was an error
                        console.warn('Something went wrong.', err);
                        form.querySelector('.thom-form__error').classList.remove('visuallyhidden');
                        form.classList.remove('loading');
                    });
                } else {
                    form.querySelector('.thom-form__error').classList.remove('visuallyhidden');
                    form.classList.remove('loading');
                }
            }

        })
    }

    let deleteUserDialogEl = document.getElementById('delete-user-dialog');
    if(deleteUserDialogEl){
        // Instantiate a new A11yDialog module
        let deleteUserDialog = new A11yDialog(deleteUserDialogEl);
        deleteUserDialog
            .on('show', () => {
                disableBodyScroll(deleteUserDialogEl);
            })
            .on('hide', () => {
                enableBodyScroll(deleteUserDialogEl);
            })
    }

    if(document.querySelector('.generate_pdf')){
        document.querySelector('.generate_pdf').setAttribute('data-barba-prevent','self');
        document.querySelector('.generate_pdf').addEventListener( 'click', function(e) {
            e.preventDefault();

            var loader = document.createElement('div');
            loader.className = 'loader';
            
            // var spinner = document.createElement('span');
            // spinner.className = 'spinner';

            // loader.appendChild(spinner);

            var spinner = document.createElement('span');
            spinner.className = 'brand';
            spinner.innerHTML = document.querySelector('.navbar--brand').innerHTML;

            loader.appendChild(spinner);

            document.querySelector('.product').appendChild(loader);
            document.querySelector('.product .loader').classList.add('show');

            var url = this.getAttribute('href');
            var filename = this.getAttribute('data-pdf-name');

            var request = new XMLHttpRequest();
            request.open('POST', url, true);
            // request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
            request.responseType = 'blob';

            request.onload = function() {
                // Only handle status code 200
                if(request.status === 200) {
                    // Try to find out the filename from the content disposition `filename` value
                    // var disposition = request.getResponseHeader('content-disposition');
                    // console.log(disposition)
                    // var matches = /"([^"]*)"/.exec(disposition);
                    // var filename = (matches != null && matches[1] ? matches[1] : 'file.pdf');
                    // console.log(filename)

                    // The actual download
                    var blob = new Blob([request.response], { type: 'application/pdf' });
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = filename;

                    document.body.appendChild(link);

                    link.click();

                    document.body.removeChild(link);

                }

                document.querySelector('.product .loader').remove();
            
            // some error handling should be done here...
            };

            request.send();
        })
    }

    // Newsletter Dialog
    if(document.getElementById('newsletter-dialog')){
        var dialogNewsletterEl = document.getElementById('newsletter-dialog');
        var dialogNewsletter = new A11yDialog(dialogNewsletterEl);

        dialogNewsletter
            .on('show', () => {
                disableBodyScroll(dialogNewsletterEl);
                grecaptchaBadgeShow();

                Scrollbar.init(document.querySelector('.newsletter-dialog'), {
                    alwaysShowTracks: false
                });

            })
            .on('hide', () => {
                enableBodyScroll(dialogNewsletterEl);
                grecaptchaBadgeHide();
                document.querySelector('.thom-form:not(#newsletterForm)') ? document.querySelector(".grecaptcha-badge").classList.remove('hide') : document.querySelector(".grecaptcha-badge").classList.add('hide');
            })
    }

    let alertButtons = document.querySelectorAll('[data-alert]');
    [...alertButtons].forEach((button) => {
        button.addEventListener( 'click', function(e) {
            e.preventDefault();
            var text = button.getAttribute('data-alert');
            MyThomFormResponseAction('', text);
        })
    })

    // console.log('function APP FINISH')
}

// Back button and barba history
if (history.scrollRestoration) {
    history.scrollRestoration = "manual";
}
let scrollPosY = [0];

/* CONTENT 
LOADED */
document.addEventListener("DOMContentLoaded", () => {
     
});

barba.init({
    // We don't want "synced transition"
    // because both content are not visible at the same time
    // and we don't need next content is available to start the page transition
    // sync: true,
    // requestError: (trigger, action, url, response) => {
    //     console.log(trigger)
    //     console.log(action)
    //     console.log(url)
    //     console.log(response)
    //     console.log(response.status)
    //     console.log(response.message)
    // },
    // debug: true,
    // logLevel: 'warning',
    timeout: 8000,
    prefetchIgnore: true,
    prevent: ({ el }) => el.hasAttribute("data-alert"),
    transitions: [{
      // NB: `data` was not used.
      // But usually, it's safer (and more efficient)
      // to pass the right container as a paramater to the function
      // and get DOM elements directly from it
      async leave(data) {
        // console.log('transitions LEAVE')
        // Not needed with async/await or promises
        // const done = this.async();
        await pageTransitionIn(data);
        // No more needed as we "await" for pageTransition
        // And i we change the transition duration, no need to update the delay…
        // await delay(1000)

        // Not needed with async/await or promises
        // done()

        // Loading screen is hiding everything, time to remove old content!
        // console.log(data.current.container)
        data.current.container.remove()
      },

      async enter(data) {
        // console.log('transitions ENTER')
        await pageTransitionOut(data.next.container, data, data.next.namespace);
        
      },
      // Variations for didactical purpose…
      // Better browser support than async/await
      // enter({ next }) {
      //   return pageTransitionOut(next.container);
      // },
      // More concise way
      // enter: ({ next }) => pageTransitionOut(next.container),

      async once(data) {
        // console.log('transitions ONCE')
        // Navbar
        // console.log(data.next.container)
        await pageTransitionIn(data)
        await pageTransitionOut(document, data, data.next.namespace);
        // await contentAnimation(data.next.container);
      },

      async afterLeave(data) {
        // console.log('transition AFTER LEAVE')

        // Kill scrolltrigger instande
        let tlChildren = gsap.globalTimeline.getChildren();
        if(tlChildren){
            tlChildren.forEach(child => {
                child.kill();
                child = null;
            });
        }

        // Inject scripts on page
        const js = data.next.container.querySelectorAll("script");
        [].forEach.call(js, function (script) {
            // console.log(script);
            window.eval(script.innerHTML);
        });
      }
    }]
});

let pageTransition = document.querySelector('.pageTransition');
let pageTransitionBlu = pageTransition.querySelector('.blu-screen');
let pageTransitionWhite = pageTransition.querySelector('.white-screen');
let mainHeader = document.querySelector('.header');
// let pageTransitionLoader = pageTransition.querySelector('.pageTransitionLoader');

function pageTransitionIn(data) {
    // console.log('Function page Transition IN');
    // console.log(data);
    // GSAP methods can be chained and return directly a promise
    // but here, a simple tween is enough
    return gsap
        .timeline()
        .add('start') // Use a label to sync screen and content animation
        .set(pageTransition, {
            yPercent: 0,
            opacity: 1
        }, 'start')
        .to(pageTransitionBlu, {
            duration: 0.8,
            yPercent: 0,
            transformOrigin: 'top left',
            ease: 'power2.out'
        }, 'start')
        .to(pageTransitionWhite, {
            duration: 0.8,
            yPercent: 0,
            transformOrigin: 'top left',
            ease: 'power3.out',
        }, 0.3, 'start')
        // .to(pageTransitionLoader, {
        //     duration: 0.5,
        //     opacity: 1,
        //     ease: 'power1'
        // }, 1, 'start')
}
// Function to add and remove the page transition screen
function pageTransitionOut(container, data, namespace) {
    // console.log('Function page Transition OUT')

    if(data && data.trigger !== "back") {
        scrollPosY.push(barba.history.current.scroll.y);
    }

    if(data.trigger !== "back") {
        window.scrollTo(0, 0, "instant");
      } else {
        window.scrollTo(0, scrollPosY.pop())
    }
    
    // GSAP methods can be chained and return directly a promise
    return contentAnimation(container, data, namespace)
}

// Function to animate the content of each page
function contentAnimation(container, data, namespace) {
    (async () => {
        // console.log('Function content ANIMATION')

        let startMyApp = await App(container, data);
        let hideLoading = await loadingHide(data);
        return {
            startMyApp,
            hideLoading
         };
    })()    
}

function loadingHide(data) {
    // console.log('function LOADING HIDE')
    // console.log(data)
    // pageTransition.classList.remove('show');
    
    gsap
        .timeline()
        .add('end') // Use a label to sync screen and content animation
        // .to(pageTransitionLoader, {
        //     duration: 0.35,
        //     opacity: 0,
        //     ease: 'power1'
        // }, 'end')
        .to(pageTransitionBlu, {
            duration: 0.1,
            yPercent: 100,
            transformOrigin: 'top left',
            ease: 'power2.out',
            onStart: () => {
                if(data.trigger !== "back") {
                    setTimeout(function(){
                        window.scrollTo(0, 0, "instant")
                    }, 10);
                }
            }
        }, 'end')
        .to(pageTransitionWhite, {
            duration: 0.8,
            yPercent: 100,
            transformOrigin: 'top left',
            ease: 'power3.out',
            onComplete: () => {
                // console.log('onComplete')
                // gsap.set(pageTransitionLoader, {
                //     opacity: 0
                // })

                gsap.set(pageTransitionWhite, {
                    yPercent: -100
                })
                gsap.set(pageTransitionBlu, {
                    yPercent: -100
                })
                gsap.set(pageTransition, {
                    yPercent: -100,
                    opacity: 0,
                    pointerEvents: "none"
                }, 'end')
            }
        }, .05, 'end')
}
