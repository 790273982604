/**
 * Navbar
 */

//  import PerfectScrollbar from 'perfect-scrollbar';
//  import 'perfect-scrollbar/css/perfect-scrollbar.css';
import { gsap } from "gsap";
import { isTouchDevice } from '../../scripts/components/utils';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const hoverIntent = (el, onOver, onOut) => {
  let x;
  let y;
  let pX;
  let pY;
  const h = {};
  let state = 0;
  let timer = 0;

  let options = {
      sensitivity: 7,
      interval: 100,
      timeout: 0,
      overClass: 'hovered'
  };

  const delay = e => {
      if (timer) timer = clearTimeout(timer);
      state = 0;
      if (onOut) {
          return onOut.call(el, e);
      }
      el.classList.remove(options.overClass);
      return false;
  };

  const tracker = e => {
      x = e.clientX;
      y = e.clientY;
  };

  const compare = e => {
      if (timer) timer = clearTimeout(timer);
      if (Math.abs(pX - x) + Math.abs(pY - y) < options.sensitivity) {
          state = 1;
          if (onOver) {
              return onOver.call(el, e);
          }
          el.classList.add(options.overClass);
          return false;
      }
      pX = x;
      pY = y;
      timer = setTimeout(() => {
          compare(e);
      }, options.interval);
      return false;
  };

  // Public methods

  const dispatchOver = e => {
      if (timer) timer = clearTimeout(timer);
      el.removeEventListener('mousemove', tracker, false);

      if (state !== 1) {
          pX = e.clientX;
          pY = e.clientY;

          el.addEventListener('mousemove', tracker, false);

          timer = setTimeout(() => {
              compare(e);
          }, options.interval);
      }

      return this;
  };

  const dispatchOut = e => {
      if (timer) timer = clearTimeout(timer);
      el.removeEventListener('mousemove', tracker, false);

      if (state === 1) {
          timer = setTimeout(() => {
              delay(e);
          }, options.timeout);
      }

      return this;
  };

  if (el) {
      el.addEventListener('mouseover', dispatchOver, false);
      el.addEventListener('mouseout', dispatchOut, false);
  }

  h.options = opt => {
      options = { ...options, ...opt };
      return h;
  };

  h.remove = () => {
      if (!el) return;
      el.removeEventListener('mouseover', dispatchOver, false);
      el.removeEventListener('mouseout', dispatchOut, false);
  };

  return h;
};

// let header = '';
// let targetElement = '';
// let navbarToggler = '';
// let pinOffset = 0;
// let prevScrollPos;

const navbar = (container) => {
  let header = container.querySelector('.header');
  let targetElement = container.querySelector('.js-navbar');
  let navbarToggler = targetElement.querySelectorAll('.js-navbar-toggler');

  window.addEventListener('scroll', requestTick, false);

  // Get the offset position of the navbar
  let pinOffset = 0;

  // keep track of previous scroll position
  let prevScrollPos = window.scrollY;

  // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
  let update = () => {
    let currentScrollPos = window.scrollY;
    if (window.scrollY > pinOffset) {
      if (prevScrollPos > currentScrollPos) {
        // user has scrolled up
        container.querySelector('.header').classList.add('pin');
        container.querySelector('.header').classList.remove('out');
      } else {
        // user has scrolled down
        container.querySelector('.header').classList.add('out');
        container.querySelector('.header').classList.remove('pin');
        container.querySelector('.header').classList.remove('submenu--opened');

        targetElement.querySelectorAll('.submenu').forEach(function(submenu) {
          submenu.classList.remove('show')
        })
        
      }
    } else {
      container.querySelector('.header').classList.remove("pin");
      container.querySelector('.header').classList.remove("out");
    }

    // update previous scroll position
    prevScrollPos = currentScrollPos;
  }

  function requestTick() {
    requestAnimationFrame(update);
  }

  let open = () => {
    document.body.classList.add('navbar--opened');
    disableBodyScroll(document);
  }

  let close = () => {
    document.body.classList.remove('navbar--opened');
    enableBodyScroll(document)
  }

  let showHideSubmenu = (submenu) => {
    container.querySelectorAll('.submenu').forEach(el => {
      if(el != submenu){
        el.classList.remove('show')
      }
    });

    if(submenu.querySelector('input')){
      submenu.querySelector('input').focus({ focusVisible: true });
    }

    if(submenu.classList.contains('show')){
      // console.log('submenu CHIUDO')
      header.classList.remove('submenu--opened');
      submenu.classList.remove('show');
    } else {
      // console.log('submenu APRO')
      // console.log(header)
      header.classList.add('submenu--opened');
      submenu.classList.add('show');

      container.removeEventListener('click', targetEvt);

      container.addEventListener("click", function(evt) {
        targetEvt(evt);
      })
    }
  }

  // Menù Button to open Menu
  if (navbarToggler) {
    close();

    navbarToggler.forEach(function(item) {
      
      item.addEventListener('click', function (event) {
        event.preventDefault();      
        if(document.body.classList.contains('navbar--opened')){
          close();
        } else {
          open();
        }
      });
    });    
  }

  // Submenu

  if (!isTouchDevice()) {
    hoverIntent(header);
  }

  let showDelay = 0, 
      hideDelay = 100;
	let menuEnterTimer, 
      menuLeaveTimer;
  let menuItems = '';
  menuItems = container.querySelectorAll('.navbar--menu--item');
  [...menuItems].forEach(function(item) {
    let submenu = item.nextElementSibling;
    while (submenu) {
      if (submenu.classList.contains('submenu')) {
        
        if (!isTouchDevice()) {
          // console.log('non touch')
          // Mouse over/out event on no-touch device
          item.addEventListener('mouseenter', function() {
            // clear the opposite timer            
            clearTimeout(menuLeaveTimer);
            
            container.querySelectorAll('.submenu').forEach(el => {
              if(el != submenu){
                el.classList.remove('show');
              }
            })
            // hide any active dropdowns
            for (let j = 0; j < menuItems.length; j++) {
              menuItems[j].classList.remove('show');
            }
            // add active class after a delay
            menuEnterTimer = setTimeout(function() {
              // submenu.classList.add('show');
              // let submenu = thisItem.nextElementSibling;
              if(submenu.querySelector('input')){
                submenu.querySelector('input').focus({ focusVisible: true });
              }

              container.querySelector('.header').classList.add('submenu--opened');
            }, showDelay);
          });

          // triggered when user's mouse leaves the menu item
          item.addEventListener('mouseleave', function() {
            // clear the opposite timer
            clearTimeout(menuEnterTimer);
            // remove active class after a delay
            menuLeaveTimer = setTimeout(function() {
              // submenu.classList.remove('show');
              container.querySelector('.header').classList.remove('submenu--opened');
            }, hideDelay);
          });

          submenu.addEventListener('mouseenter', function() {
            // console.log('submenu mouseenter')
            // clear the opposite timer
            clearTimeout(menuLeaveTimer);
            // hide any active dropdowns
            for (let j = 0; j < menuItems.length; j++) {
              menuItems[j].classList.remove('show');
            }
            // add active class after a delay
            menuEnterTimer = setTimeout(function() {
              // submenu.classList.add('show');
              container.querySelector('.header').classList.add('submenu--opened');
            }, showDelay);
          });

          // triggered when user's mouse leaves the menu item
          submenu.addEventListener('mouseleave', function() {
            // console.log('submenu mouseleave')
            // clear the opposite timer
            clearTimeout(menuEnterTimer);
            // remove active class after a delay
            menuLeaveTimer = setTimeout(function() {
              // submenu.classList.remove('show');
              container.querySelector('.header').classList.remove('submenu--opened');
            }, hideDelay);
          });
        } else {
          // console.log('touch')
          // Click event
          item.removeEventListener('click', showHideSubmenu);
          item.addEventListener('click', function (event) {
            event.preventDefault();
            showHideSubmenu(submenu);    
            // console.log(submenu)        
          })
        }

        var links = submenu.querySelectorAll('.submenu--nav li');
        var images = submenu.querySelectorAll('.submenu--preview .layer');
        var oldImage = null;

        let addListeners = (el) => {
          el.addEventListener("mouseover", function() {
            var index = [].slice.call(el.parentNode.children).indexOf(el);
            changeImage(images[index]);
          });
          el.addEventListener("mouseout", function() {
            gsap.to(oldImage, {opacity: 0, scale: 1, duration: 0.6, ease: "power2.outIn"});
            oldImage = null;
          });
        }

        let changeImage = (el) => {
          // console.log(el)
          if(el != oldImage) {
            let tl = gsap.timeline();
            // console.log(tl)
            // console.log(oldImage)
            
            if(oldImage != null) {
              tl.to(
                oldImage, {
                  opacity: 0,
                })
            }
            
            tl.to(el, {
              opacity: 1, 
              duration: 0.6, 
              ease: "power2.outIn"
            }, "<");
            
            oldImage = el;
          }
        }

        if (!isTouchDevice()) {
          links.forEach(el => addListeners(el) );
        }

        break;
      }
    }    
  })

  let targetEvt = (evt) => {
    let targetEl = evt.target; // clicked element     
    // console.log(targetEl) 
    do {
      if(targetEl == targetElement) {
        // This is a click inside, does nothing, just return.
        return;
      }
      // Go up the DOM
      targetEl = targetEl.parentNode;
    } while (targetEl);
    // console.log(targetEl)
    targetElement.querySelectorAll('.submenu').forEach(function(submenu) {
      submenu.classList.remove('show')
    })
    header.classList.remove('submenu--opened');
  }

}

export {navbar};
